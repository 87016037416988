import * as events from '../events';
import genericEvents from './generic-events';

export const identificationTypes = Object.freeze({
    IFRAME: 'IFRAME',
    PREID: 'PRE-ID',
    AJAX: 'AJAX',
})

export class LoginFlowBase {
    /**
     *
     * @param {string} name
     * @param {identificationTypes} identification
     * @param {Object} args //An object that overrides any of the fields in the class after they have been overridden by LP.js
     */
    constructor(name, identification, args) {

        this.setupClassProps()
        //check class has been setup correctly
        if (!identification in identificationTypes) {
            throw new Error('Identification method not found.')
        }

        if (typeof args != 'object') {
            throw new Error("Args must be included.")
        }
        this.name = name;
        this.identification = identification;
        this.args = args;
    }

    setupClassProps() {
        this.identificationMethods = {
            [identificationTypes.IFRAME]: this.iframeIdentify.bind(this),
            [identificationTypes.AJAX]: this.ajaxIdentify.bind(this),
        }

        this.uiControl = {}
        this.flowSteps = {
            SPINNER: 'spinner',
            INIT: 'init',
            ERROR: 'error',
            TIMEOUT: 'timeout',
            WIFI: 'wifi',
        }
        this.tracker = {}
        this.dispatcher = {}
        this.integrator = {}
        this.territory = ''
        this.integratorIdentifyUrl = ''
        this.logger = {}
        this.msisdn = ''
        this.internalCall = false
        this.timeoutLimit = 180000 // 3 minutes
        this.plan = ''
        this.productSlug = ''
        this.metadata = {}
        this.identified = false;
        this.trackConversions = false;
        this.identifyCallFinished = false;
        this.identity = ""
        this.productUrl = ""
        this.locale = ""
        this.integratorIdentity = ""
        this.checkedPlans = []
        this.heAuthToken = ""
    }

    overwriteClassProps() {
        for (const [key, value] of Object.entries(this.args)) {
            this[key] = value
        }
    }


    init() {
        this.integrator.appendBaseData({ territory: this.territory });
        this.logger.debug(`Flow (${this.name}): initialising flow`);
        this.bindClickEvents();
        this.setFlowStep(this.flowSteps.SPINNER);
        this.identify();
    }

    identify() {
        if (!this.internalCall) {
            this.identificationMethods[this.identification].bind(this)()
        }
    }

    handleIntegratorIframeIdentity() {
        this.integrator.heIframeIdentify(
            {
                returnUrl: window.location.href,
            }
        );
    }

    handleIntegratorIframeIdentityCustomPlan(plan) {
        const base = this.integratorIdentifyUrl.split('?')[0];

        this.integrator.customHeIframeIdentify(
            base,
            'he',
            {
                return: window.location.href,
                metadata: this.metadata,
                plan: plan
            }
        );
    }

    iframeIdentify() {
        this.handleIntegratorIframeIdentity()
        window.addEventListener('message', (event) => {
            //event is the response data sent from the flow
            //call on message here and use the onSuccess onError defined above since the heResponse is the same
            //where does event come from when doing iframeIdentity
            this.onMessage(event, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this))
        }, false);
    }

    ajaxIdentify() {
        this.integrator.heIdentifyGetRequest({
            return: window.location.origin + window.location.pathname,
            identity: this.integratorIdentity,
        }, this.identifyOnSuccess.bind(this), this.identifyOnError.bind(this));
    }

    onMessage(event, onSuccess, onError) {
        //check the iframe event and then call the correct one
        const res = event.data;
        if (res.status_code === 200) {
            onSuccess(res);
        } else if (res.status_code === 302) {
            if (res.jwt) {
                this.onSubscriptionExists(res)
            } else {
                onError(res)
            }
        } else {
            onError(res)
        }
    }

    onSubscriptionExists(response){
        this.tracker.track(events.SUBSCRIPTION_EXISTS, {
            data: {
                reference: response.reference,
            },
        });
        this.redirectToProduct(response.jwt);
    }

    identifyOnSuccess(response) { // overridable
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        if (this.identified) {
            this.uiControl.showErrorMessage(`No subscription found`)
        } else {
            this.logger.debug(`[Identity Failure] id response`, response);
            this.tracker.track(events.IDENTIFY_FAILURE, {
                reference: response.reference,
                status_code: response.status_code,
            });
            this.identifyBackup()
        }
    }

    identifyOnError(response) { // overridable, this should call identifybackup()
        this.identity = response.identity;
        this.identified = response.identified;
        this.identifyCallFinished = true;
        //change this to use our system
        this.logger.error(`[Identity Error] Flow (${this.name}): received error from integrator`, response);
        this.tracker.track(events.IDENTIFY_ERROR, {
            reference: response.reference,
            status_code: response.status_code,
        });
        this.identifyBackup()
    }
    identifyBackup() {
        //OTP not enabled just do WIFI
        this.tracker.track(events.SHOW_WIFI);
        this.setFlowStep(this.flowSteps.WIFI)
        this.uiControl.showWifiFlowWithRefreshPage();
    }

    isIdentifyCallFinished() {
        return this.identifyCallFinished;
    }

    redirectToProduct(jwt) {//overridable
        // remove unload message
        window.onbeforeunload = null;

        const redirectUrl = this.getProductRedirectUrl(this.productUrl,'login', {
            auth: jwt,
            identified: 1,
            event_tracking_id: this.metadata.event_tracking_id
        });

        this.logger.debug(`Flow (${this.name}): redirecting to product`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
                withAuth: true
            },
        });

        window.location.assign(redirectUrl);
    }

    redirectToProductWithoutAuth() {//overridable
        // remove unload message
        window.onbeforeunload = null;
        const redirectUrl = this.getProductRedirectUrl(this.productUrl,'', {});

        this.logger.debug(`Flow (${this.name}): redirecting to product without auth`, redirectUrl);
        this.tracker.track(events.REDIRECT_TO_PRODUCT, {
            data: {
                redirectUrl: redirectUrl,
                withAuth: false,
            },
        });

        window.location.assign(redirectUrl);
    }

    getProductRedirectUrl(baseUrl = this.productUrl,pathname = 'login', params) {
        let redirectUrl = new URL(baseUrl);
        redirectUrl.pathname = pathname;
        redirectUrl.searchParams.append('locale', this.locale);
        for (var key in params) {
            redirectUrl.searchParams.append(key, params[key]);
        }
        return redirectUrl.href;
    }

    bindClickEvents() {
        genericEvents.bindWifiClickEvent(this);
        genericEvents.bindFlowExitEvent(this);
    }

    setFlowStep(flowStep) {
        this.logger.debug(`Flow (${this.name}): changing step from ${this.step} to ${flowStep}`);
        this.step = flowStep;
        this.uiControl.setPageState(flowStep);
        //Some flowsteps are triggered in this flow for CSS purpose and therefore aren't included here. They can be added in the future though.
        switch (flowStep) {
            case this.flowSteps.WIFI:
                this.uiControl.hideElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                this.uiControl.showElement(this.uiControl.controls.wifi);
                break;
            case this.flowSteps.SPINNER:
                this.uiControl.showElement(this.uiControl.controls.spinner);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.button);
                this.uiControl.hideElement(this.uiControl.controls.contentClick.container);
                this.uiControl.hideElement(this.uiControl.controls.subscribe);
                this.uiControl.hideElement(this.uiControl.controls.confirm);
                break;
            default:
                break;
        }
    }

    redirectToLandingPage() {
        // remove unload message
        window.onbeforeunload = null;

        const landingPageUrl = this.metadata['page_attributes-subscribe_landing_page'];

        if (landingPageUrl) {

            const landingPageURLOBJ = new URL(landingPageUrl)

            const url = this.getProductRedirectUrl(landingPageURLOBJ.origin, landingPageURLOBJ.pathname, {
                event_tracking_id: this.metadata.event_tracking_id
            });

            this.tracker.track(events.REDIRECT_TO_LANDING_PAGE, {
                landingPage: url
            });

            this.logger.info(`Flow (${this.name}): redirecting to landing page`, {
                "LPUrl": url
            });

            window.location.assign(url);
        } else {
            this.logger.info(`Flow (${this.name}): redirecting to base landing page URL`, {"LPUrl": window.location.origin});

            this.uiControl.showErrorMessage(`No subscription found`)
        }
    }
}
